import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import kereszty from 'assets/images/kereszty_dentalmikroskop.jpg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { FormattedMessage } from "gatsby-plugin-intl"
import { Header } from 'components/theme';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Dentalmikroskop = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <StaticQuery
      query={graphql`
        query DentalmikroskopQuery {
          file(relativePath: { eq: "kereszty_dentalmikroskop.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          } 
        }
      `}
      render={data => (
        <Wrapper>
          <Header />
          <SkillsWrapper as={Container}>
            <Thumbnail>
              <Img
                      fluid={data.file.childImageSharp.fluid}
                      alt="dr. Kereszty Praxis, dentalmikroskop"
                    />
            </Thumbnail>
            <Details theme={theme}>
              <div>
                <h2><FormattedMessage id="dentalmikroskop.title" /></h2> 
                <p><FormattedMessage id="dentalmikroskop.text.1" /></p>
                <p><FormattedMessage id="dentalmikroskop.text.2" /></p>
                <p><FormattedMessage id="dentalmikroskop.text.3" /></p>
              </div>
              
            </Details>
          </SkillsWrapper>
        </Wrapper>
      )}
    />
  )
};
