import React from 'react';
import { Layout, SEO } from 'components/common';
import { Dentalmikroskop } from 'components/landing/Dentalmikroskop';

export default () => (
  <Layout>
    <SEO />
    <Dentalmikroskop/>
  </Layout>
);
